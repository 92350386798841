import React, { useEffect, useState } from "react";
import { LoginModal, OrderDetailsContainer } from "tf-checkout-react";
import { toast } from 'react-toastify';
import { setTfCheckoutReactConfigs } from "../../utils/tf-checkout-config";
import Layout from "../../components/layout/index";
import Head from "../../components/head/index";
import Logo from "../../assets/images/mana_footer.png";
import { X_TF_ECOMMERCE } from "../../components/header";
import { getCookieByName } from "../../utils/cookies";
import "./style.scss";

setTfCheckoutReactConfigs();

const OrderDetails = () => {
  const isWindowDefined = typeof window !== "undefined";
  const [isLogged, setIsLogged] = useState(
    isWindowDefined ? !!getCookieByName(X_TF_ECOMMERCE) : false
  );

  const handleOnLogin = () => {
    setIsLogged(true);
  };

  return (
    <>
      <Head title="Order Details" />
      <Layout>
        {isLogged ? (
          <OrderDetailsContainer
            columns={[
              { label: "Items" },
              { label: "Price" },
              { label: "Quantity" },
              { label: "Total" },
            ]}
            onUpdateOrderCustomFieldsError={() => {
              toast.error("Error while updating Order fields")
            }}
            onUpdateTicketHolderCustomFieldsError={() => {
              toast.error("Error while updating Holder custom fields")
            }}
          />
        ) : (
          <LoginModal logo={Logo} onLogin={handleOnLogin} />
        )}
      </Layout>
    </>
  );
};

export default OrderDetails;
